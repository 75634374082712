<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The Haber-Bosch process involves the combination of nitrogen and hydrogen at high
        temperatures in the presence of a catalyst to produce ammonia. It is currently the main
        industrial source of ammonia.
      </p>

      <p class="mb-4 pl-6">
        <chemical-latex content="N2(g) + 3 H2(g) -> 2 NH3(g)" />
      </p>

      <p class="mb-3">
        Assuming that a reaction vessel originally contains
        <number-value :value="molesN2" unit="\text{mol}" />
        of
        <chemical-latex content="N2" />
        and
        <number-value :value="molesH2" unit="\text{mol}" />
        of
        <chemical-latex content="H2," />
        answer each of the following questions:
      </p>

      <p class="mb-2">
        a) How many moles of
        <chemical-latex content="NH3" />
        could be produced from these quantities of
        <chemical-latex content="N2" />
        and
        <chemical-latex content="H2" />
        ?
      </p>

      <calculation-input
        v-model="inputs.molesNH3"
        prepend-text="$\ce{NH3:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-2">
        b) After the reaction is complete, how many moles of
        <chemical-latex content="N2" />
        would remain?
      </p>

      <calculation-input
        v-model="inputs.molesN2Remaining"
        prepend-text="$\ce{N2:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-2">
        c) After the reaction is complete, how many moles of
        <chemical-latex content="H2" />
        would remain?
      </p>

      <calculation-input
        v-model="inputs.molesH2Remaining"
        prepend-text="$\ce{H2:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'Question69',
  components: {
    ChemicalLatex,
    NumberValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molesNH3: null,
        molesN2Remaining: null,
        molesH2Remaining: null,
      },
    };
  },
  computed: {
    molesN2() {
      return this.taskState.getValueBySymbol('molesN2').content;
    },
    molesH2() {
      return this.taskState.getValueBySymbol('molesH2').content;
    },
  },
};
</script>
